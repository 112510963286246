@media only screen and (max-width: 768px) {
    .private.page {
        margin: 0px;
        overflow-y: auto;
    }
    .main-page {
        overflow: hidden;
    }
    .header-content__menu {
        display: block;
    }
    .main-content {
        width: 100%;
        overflow: hidden;
    }
    /* LOG IN  */
    .login-page {
        align-items: center;
        flex-direction: column;
    }
    .login-form {
        width: 80%;
        position: unset;
        margin-top: 10px;
    }
    .login-page__logo {
        width: 180px;
        height: 100px;
        position: unset;
    }
    .input {
        width: 0px;
    }
    .option-item {
        min-width: 90%;
    }

    .word-searches-form__option{
        min-width: max-content;
    }
    .order-form__option {
        min-width: max-content;

    }

    .order-words__options {
        max-height: 180px;
    }
    /* SIDEBAR */
    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        min-height: 100%;
        z-index: 100;
        width: 300px;
        background-color: #294867;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        transform: translateX(-265px);
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 0px 10px rgba(0, 0, 0, .8);
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    }
    .sidebar-item__menu {
        display: block;
    }
    .responsive-wrapper {
        flex-direction: column;
    }
    .sidebar--active {
        transform: translateX(0px)
    }
    .sidebar-item__menu {
        display: flex;
    }
    .sidebar__switcher-layer {
        display: block;
    }
    /* ORDER WORDS */
    .order-words__wrapper, .quiz-questions__wrapper {
        flex-direction: column;
        /* margin-left: 20px; */
    }
    
    .order-words__list, .quiz-questions__list {
        min-height: 90%;
        overflow-y: scroll;
        align-self: center;
        border-bottom: 1px solid #cecece;
        padding-bottom: 10px;
        margin-bottom: 20px;
    }

    .add-button {
        display: block;
    }

    .word-searches-form__options-list {
        overflow: auto;
        max-height: 180px;
    }
    .order-words__form,.quiz-questions__question-form {
        position: fixed;
        right: 0px;
        bottom: 0px;
        top: 0px;
        min-height: 100vh;
        width: 90%;
        margin: 5px;
        box-sizing: border-box;
        align-self: center;
        transform: translateX(800px);
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
        border-radius: 0px;
        margin:0px;
        z-index:10;
    }
    .order-words__form--active {
        transform: translateX(0px);
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    }
    .quiz-questions__question-form {
        align-self: center;
    }
}

@media only screen and (max-width: 520px) {
    .quiz-questions__question-item {
        width: 90%;
    }
}