.order-word__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin-top: 40px;
}

.list__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background-color: white;
    color: rgb(77, 77, 77);
    margin-bottom: 5px;
    min-height: 40px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgb(236, 236, 236);
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    border-left: 2px solid #348FE5;
    cursor: pointer;
    transition:.3s;
}

.list__list-item:hover {
    background-color: #348FE5;
    color: white;
}

.list__list-item--active {
    background-color: #348FE5;
    color: white;
}

.list-item__title {
    font-size: 0.85rem;
}

.edit-mode__icon {
    font-size: 1.1rem;
    color: white;
}

.order-words__wrapper {
    display: flex;
    flex-direction: row;
    min-width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    overflow: hidden;
}

.create-btn {
    width: 80px;
}



.order-words__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 10px;
    margin-top: 10px;
    overflow: scroll;
    height: 700px;
}

.order-form__option {
    background-color: rgb(82, 156, 131);
    color: #fff;

}

.order-words__form {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 420px;
    min-height: 300px;
    padding-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgb(218, 218, 218);
    margin: 10px;
    padding: 20px;
    align-items: center;
}

.add-button {
    display: none;
}
.form__title {
    font-size: 1.1rem;
    margin-bottom: 30px;
}

.order-words__options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
}

.order-words__bottom-btns {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid rgb(218, 218, 218);
}