.quiz-questions__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.quiz-questions__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    min-width: 100%;
    min-height: 100px;
    max-height: 690px;
    overflow: scroll;

}

.quiz-questions__question-item {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgb(236, 236, 236);
    padding: 10px;
    margin: 10px;
    min-width: 200px;
    max-width: 200px;
    height: 100px;
    border-radius: 4px;
    color: rgb(77, 77, 77);
    cursor: pointer;
    transition: .3s;
    position: relative;
    z-index: 5;
}

.question-item_edit-icon {
    color: white;
    font-size: 1rem;
    position: absolute;
    bottom: 5px;
    right: 5px;
}




.quiz-questions__question-item:hover {
    background-color: #348FE5;
    color: white;
}

.quiz-questions__question-item--active {
    background-color: #348FE5;
    color: white;
}

.question-item__question-text {
    font-size: 0.7rem;
    font-weight: 600;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(218, 218, 218);
}

.question-item__options-list {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.question-item__option {
    display: flex;
    font-size: 0.6rem;
}

.question-item__option--active {
    font-weight: 600;
}

/* QUESTION FORM */

.quiz-questions__question-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgb(236, 236, 236);
    box-sizing: border-box;
    width: 600px;
    height: max-content;
}

.question-form__option {
    display: flex;
    justify-content: space-between;
    background-color: rgb(240, 240, 240);
    color: rgb(97, 97, 97);
    min-width: 300px;
    box-sizing: border-box;
}

.question-form__option--active {
    background-color: rgb(60, 145, 109);
    color: rgb(240, 240, 240);
}

.question-form__option:hover {
    background-color: rgb(60, 145, 109);
    color: rgb(240, 240, 240);
}