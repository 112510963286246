.login-page {
     display: flex;
     min-height: 100vh;
     background-color: #294867;
     background-image: url('https://images.unsplash.com/photo-1557683316-973673baf926?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1615&q=80');
     background-size: cover;
     background-repeat: no-repeat;
}

.login-form {
     width: 500px;
     height: 350px;
     border-radius: 20px;
     padding-left: 10px;
     padding-right: 10px;
     background-color: white;
     position: absolute;
     right: 100px;
     top: 20%;
     padding: 20px;
     /* box-shadow: 0px 0px 9px lightgray; */
}

.login-page__logo {
     background-image: url('../images/fm-logo.png');
     background-size: cover;
     background-repeat: no-repeat;
     width: 500px;
     height: 300px;
     position: absolute;
     left: 200px;
     top: 30%;
}

.login-form__placeholder {
     font-size: 0.75rem;
     color: rgb(70, 69, 69);
     margin-left: 3px;
     margin-bottom: 15px;
}

/* RESPONSIVE  */

