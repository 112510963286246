html, body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: 'Poppins'
}

.sidebar {
    min-height: 100%;
    z-index: 100;
    width: 300px;
    background-color: #294867;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 0px 10px rgba(0,0,0,.8);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    /* justify-content: center; */
}



.sidebar__sidebar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* width: 100%; */
    height: auto;
    cursor: pointer;
    margin-bottom: 5px;
    padding: 5px;
    border-left: 4px solid transparent;
}

.sidebar__switcher-layer {
    display: none;
    position: absolute;
    width: 40px;
    height: 100%;
    right: 0;
    top: 0px;
    z-index: 2;
    background-color: #294867;
    box-shadow: 0 1px 8px rgba(0,0,0,0.25), 0 0px 1px rgba(0,0,0,.3);
}

.sidebar-item__menu {
    display: none;
    position: absolute;
    font-size: 1.5rem;
    color: white;
    right: 5px;
    top: 10px;
    cursor: pointer;
    z-index: 6;
}

.sidebar__sidebar-item:hover {
    background-color: #223D58;
}

.sidebar__sidebar-item---active {
    background-color: #223D58;
    border-left: 4px solid #348FE5;
}

.sidebar-item__icon {
    color: #D9E6F6;
    font-size: 1.3rem;
    margin: 10px;
}

.sidebar__icon--active {
    color: #348FE5;
}

.sidebar-item__text {
    font-size: 0.9rem;
    color: #D9E6F6;
}

.sidebar-profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #D9E6F6;
    font-size: 0.85rem;
    background-color: #223D58;
    margin-top: auto;
    /* margin-left: auto; */
    margin-bottom:30px;
}

.sidebar-avatar {
    display: flex;
    background-image: url('../images/fm-logo.png');
    background-color: rgb(76, 76, 77);
    background-size: cover;
    background-position: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 0.6rem;
    margin: 10px;
    box-shadow: 0px 0px 3px rgb(238, 238, 238);
    justify-self: flex-end;
}

.sidebar__logo {
    background-image: url('../images/fm-logo.png');
    background-size: cover;
    /* background-position: center; */
    width: 160px;
    height: 90px;
    margin: 9px;
    margin-bottom: 140px;
    margin-top: 20px;
    align-self: center;
}

.sidebar-profile__user-name {
    font-size: 0.7rem;
    margin-right: 15px;
}

.sidebar__logout {
    font-size: 0.7rem;
    color: white;
    /* background-color: rgb(187, 90, 90); */
    border:1px solid rgb(226, 211, 211);
    padding: 4px;
    text-align: center;
    width: max-content;
    border-radius: 4px;
    margin-left: auto;
    margin-right: 10px;
    cursor: pointer;
}

.sidebar__logout:hover {
    opacity: 0.6;
}