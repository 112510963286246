.word-searches__word-searches-item {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgb(236, 236, 236);
    padding: 10px;
    margin: 10px;
    min-width: 200px;
    max-width: 200px;
    min-height: 100px;
    max-height: 100px;
    border-radius: 4px;
    color: rgb(77, 77, 77);
    cursor: pointer;
    transition: .3s;
    position: relative;
    z-index: 5;
    overflow-y: auto;
}

.word-searches__word-searches-item:hover {
    background-color: #348FE5;
    color: white;
}

.word-searches__word-searches-item--active {
    background-color: #348FE5;
    color: white;
}

.word-search-item__words-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px;
    width: inherit;
}

.word-search-item__word {
    background-color: rgb(60, 145, 109);
    color: white;
    font-size: 0.6rem;
    padding: 8px;
    border-radius: 15px;
    width: max-content;
    margin: 8px;
    text-align: center;
    border:1px solid white;
}

.word-search-item__grid-size {
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 0.65rem;
    font-weight: 600;
}

.word-search-item__level {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 0.65rem;
    font-weight: 600;
}


.word-searches-form__options-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.word-searches-form__option {
    background-color: rgb(60, 145, 109);
    color:white;
}