

.flex {
    display:flex;
}

.fl-col {
    flex-direction: column;
}

.pointer {
    cursor: pointer;
}

.width-mc {
    width:max-content;
}
.width-300px{
    width:300px;
}

.width-0px{
    width:0px;
}

.width-60px {
    min-width:60px;
    max-width:60px;
}

.mrl-auto {
    margin-left: auto;
}
.mrl-10px {
    margin-left:10px;
}

.mrt-10px {
    margin-top:10px;
}

.mrr-10px {
    margin-right:10px;
}

.mrr-20px {
    margin-right: 20px;
}

.mrb-10px {
    margin-bottom:10px;
}

.width-100per {
    width:100%;
}

.center {
    align-items: center;
}

.overflow-scroll {
    overflow: scroll;
}