@import './sidebar.css';
@import './login.css';
@import './orderWords.css';
@import './quizQuestions.css';
@import './wordSearches.css';
@import './responsive.css';
@import './helpers.css';
.main-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.main-content {
  background-color: #F3F3F3;
  flex: 1;
}

.private-page {
  margin: 20px;
}

/* INPUT  */

.input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 35px;
  border: 1px solid rgb(212, 212, 212);
  box-sizing: border-box;
  border-radius: 6px;
  position: relative;
  background-color: transparent;
  transition: .3s;
}

.input {
  border: none;
  flex: 1;
  min-height: 100%;
  padding-left: 15px;
  box-sizing: border-box;
  font-family: 'Poppins';
  outline: none;
  border-radius: inherit;
  background-color: transparent;
}

.input__search-icon {
  font-size: 1.1rem;
  color: #348FE5;
  margin-left: 10px;
  margin-right: 10px;
}

.input-wrapper--focus {
  border: 1px solid #348FE5;
  background-color: white;
}

.input-wrapper--error {
  border: 1px solid rgb(151, 27, 58);
}

.header-content {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(218, 218, 218);
}

.header-content__menu {
  display: none;
  color: rgb(71, 71, 71);
  height: 1.2rem;
  margin-right: 20px;
}

.header-content__title {
  font-size: 1.2rem;
  color: rgb(71, 71, 71);
  font-weight: 600;
}

.error-msg {
  font-size: 0.7rem;
  color: rgb(151, 27, 58);
}

/* ======  SWITCH  ====== */

.switch {
  display: inline-flex;
  width: 32px;
  min-width: 32px;
  height: 18px;
  min-height: 18px;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;
  transition: .3s ease;
}

.switch--off {
  background-color: #e4e6e9;
}

.switch--on {
  background-color: #348FE5;
}

.switch__indicator {
  min-width: 14px;
  min-height: 14px;
  box-shadow: 0px 1px 1px 0 rgba(33, 45, 57, 0.2);
  background-color: #ffffff;
  border-radius: 8px;
  transition: .3s ease;
}

.switch__indicator--on {
  transform: translateX(16px);
}

.switch__indicator--off {
  transform: translateX(2px);
}

.number-order {
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  background-color: #348FE5;
  border:1px solid white;
  color: white;
  border-radius: 50%;
  margin-left: 6px;
  margin-right: 10px;
}

/* BUTTON */

.button {
  display: flex;
  justify-content: center;
  min-width: max-content;
  min-height: 35px;
  background-color: #348FE5;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 0.8rem;
  padding-left: 8px;
  padding-right: 9px;
  cursor: pointer;
}

.button--white {
  border: 1px solid rgb(184, 182, 182);
  background-color: #fff;
  color: rgb(95, 95, 95);
}

.divider--bottom {
  border-bottom: 1px solid #EAEAEB;
}

.button:hover {
  opacity: 0.8;
}

.button--small {
  height: 20px;
}

.button-icon {
  font-size:1.2rem; 
  margin-right:5px;
}

/* OPTION box */

.option-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px;
  border-radius: 4px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  width: max-content;
  max-width: 250px;
}

.option-item__text {
  font-size: 0.85rem;
}

.option-item__close {
  font-size: 1.1rem;
  margin-left: 10px;
  cursor: pointer;
}

.option-item__close:hover {
  color: rgb(156, 45, 45);
}

.time {
  display: flex;
  flex-direction: row;
  border-left: 3px solid #348FE5;
  padding: 5px;
  align-items: center;
  margin-top: 40px;
  width: 100%;
}

.time-input {
  margin: 5px;
  width: 50px;
  border: none;
  outline: none;
  border-bottom: 1px solid rgb(70, 71, 71);
  text-align: center;
  font-size: 0.85rem;
  font-weight: 600;
}

.time-input:focus {
  border-bottom: 1px solid #348FE5;
}

.time__icon {
  font-size: 1.3rem;
  color: #348FE5;
  margin-right: 8px;
}

.time__text {
  font-size: 0.8rem;
  color: #348FE5;
  margin-right: 10px;
}

/* =======  LOADER  ======= */

.loader {
  width: 25px;
  height: 24px;
}

.loader--center {
  position: absolute;
  top:50%;
  left:45%;
  align-self: center;
}

.loader__circular {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.loader__path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 2.5s ease-in-out infinite, 6s ease-in-out infinite;
  animation: dash 2.5s ease-in-out infinite, 6s ease-in-out infinite;
  stroke-linecap: round;
  stroke-width: 5px;
  stroke: white;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@media only screen and (max-width: 768px) {}